
import { computed, defineComponent, reactive } from "vue";

import { Auth } from "aws-amplify";

import { useStore } from "@/store";
import { ActionType } from "@/store/actions";

import BioSection from "./BioSection.vue";
import AddSection from "./AddSection.vue";
import QuickLinksSection from "./QuickLinksSection.vue";

export default defineComponent({
  components: {
    BioSection,
    AddSection,
    QuickLinksSection,
  },
  async setup() {
    const store = useStore();
    const userData = computed(() => store.getters.getUserData);
    if (userData.value === undefined) {
      await store.dispatch(ActionType.GetUserData);
    }

    const state = reactive({
      userData: userData.value!,
      editing: false,
      userLink: "https://creatory.xyz/" + userData.value!.Username,
      store,
    });

    return {
      state,
    };
  },
  methods: {
    signOut() {
      Auth.signOut().then(() => {
        this.state.store.dispatch(ActionType.InvalidateLocalUserData);
        this.$router.push("/");
      });
    },
  },
});
