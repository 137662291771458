
import { computed, defineComponent, reactive } from "vue";
import { uuid } from "vue-uuid";
import draggable from "vuedraggable";
import { useRoute } from "vue-router";

import { useStore } from "@/store";
import { ActionType } from "@/store/actions";

import { UserData } from "@/model/user";
import { updateUserProfile, fetchPublicUserProfile } from "@/model/user";

export default defineComponent({
  props: {
    editable: Boolean,
  },
  components: {
    draggable,
  },
  async setup(props) {
    const state = reactive({
      userData: {} as UserData,
      localUserData: {} as UserData,
      editing: false,
      drag: false,
      editable: props.editable,
    });

    if (props.editable) {
      const store = useStore();
      const userData = computed(() => store.getters.getUserData);
      if (userData.value === undefined) {
        await store.dispatch(ActionType.GetUserData);
      }
      state.userData = userData.value!;
    } else {
      const route = useRoute();
      let resp = await fetchPublicUserProfile(
        route.params["username"] as string
      );
      state.userData = resp.data as UserData;
    }

    if (state.userData.QuickLinks !== undefined) {
      state.userData.QuickLinks.sort((A, B) => {
        if (A.Ordering < B.Ordering) return -1;
        if (A.Ordering > B.Ordering) return 1;
        return 0;
      });
    }

    try {
      state.localUserData = JSON.parse(JSON.stringify(state.userData));
    } catch (e) {
      console.log("Failed to initialize user obj copy!");
    }

    return {
      state,
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  methods: {
    editProfile() {
      console.debug("Editing user quick links section");
      this.state.editing = true;
    },
    saveProfileChanges() {
      console.debug("Saving user quick links changes");

      updateUserProfile(this.state.localUserData)
        .then(() => {
          try {
            this.state.userData = JSON.parse(
              JSON.stringify(this.state.localUserData)
            );
          } catch (e) {
            console.error("Failed to update user obj!");
          }
          const store = useStore();
          store.dispatch(ActionType.GetUserData);
          this.state.editing = false;
          console.log("Successfully updated user profile section!");
        })
        .catch((e) => {
          console.error("Failed to update profile section!");
        });
    },
    discardProfileChanges() {
      console.debug("Discarding quick links changes");
      this.setLocalToServerData();
      this.state.editing = false;
    },
    rowStyle(id: string, field: string): string {
      let rowExists = this.state.userData.QuickLinks!.reduce(
        (val, current) => val || current.Id === id,
        false
      );
      if (!rowExists) {
        return "border border-blue-900 bg-blue-100";
      }
      let local: { [key: string]: string } =
        this.state.localUserData.QuickLinks!.find(
          (val) => val.Id === id
        ) as any;
      let cloud: { [key: string]: string } =
        this.state.userData.QuickLinks!.find((val) => val.Id === id) as any;
      if (local[field] !== cloud[field]) {
        return "border border-blue-900 bg-blue-100";
      }
      return "border border-green-900 bg-green-100";
    },
    setLocalToServerData() {
      try {
        this.state.localUserData = JSON.parse(
          JSON.stringify(this.state.userData)
        );
      } catch (e) {
        console.error("Failed to initialize user obj copy!");
      }
    },
    addNewRow() {
      if (this.state.localUserData.QuickLinks === undefined)
        this.state.localUserData.QuickLinks = [];

      let id = uuid.v1();
      let idExists = true;
      while (idExists) {
        for (let i = 0; i < this.state.localUserData.QuickLinks.length; i++) {
          if (this.state.localUserData.QuickLinks[i].Id === id) {
            id = uuid.v1();
            continue;
          }
        }
        idExists = false;
      }
      let num =
        this.state.localUserData.QuickLinks.reduce(
          (currentMax, obj) => Math.max(currentMax, obj.Ordering),
          0
        ) + 1;

      this.state.localUserData.QuickLinks.push({
        Id: id,
        Ordering: num,
        DisplayText: "AwesomeLink",
        Url: "https://creatory.xyz",
      });
    },
    removeRow(id: string) {
      this.state.localUserData.QuickLinks =
        this.state.localUserData.QuickLinks!.filter(
          (current) => current.Id !== id
        );
      this.refreshLocalData();
    },
    refreshLocalData() {
      this.state.localUserData.QuickLinks?.forEach((elem, index) => {
        elem.Ordering = index;
      });
      this.state.localUserData.QuickLinks!.sort((A, B) => {
        if (A.Ordering < B.Ordering) return -1;
        if (A.Ordering > B.Ordering) return 1;
        return 0;
      });
    },
  },
});
