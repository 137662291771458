
import { reactive, defineComponent } from "vue";

import mixpanel from "mixpanel-browser";

import Navbar from "@/components/Navbar.vue";
import FooterPanel from "@/components/Footer.vue";
import BioSection from "@/components/profile/BioSection.vue";
import QuickLinksSection from "@/components/profile/QuickLinksSection.vue";

import { fetchPublicUserProfile } from "@/model/user";

export default defineComponent({
  components: {
    Navbar,
    BioSection,
    QuickLinksSection,
    FooterPanel,
  },
  setup() {
    const state = reactive({
      userData: {},
    });

    return {
      state,
    };
  },
  async mounted() {
    mixpanel.track("Public Profile Page Visit");

    const username = this.$route.params["username"] as string;

    let data = await fetchPublicUserProfile(username);

    if (data.statusCode === 404) {
      this.$router.push("/creator/houdini");
    }

    this.state.userData = data;
  },
});
