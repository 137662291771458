
import { computed, defineComponent, reactive } from "vue";
import { Auth } from "aws-amplify";

import { useStore } from "@/store";
import { ActionType } from "@/store/actions";

export default defineComponent({
  setup() {
    const store = useStore();
    const state = reactive({
      email: "",
      password: "",
      error: false,
      store,
    });

    return {
      state,
    };
  },

  methods: {
    submitForm() {
      this.state.store.dispatch(ActionType.InvalidateLocalUserData);
      this.state.error = false;
      this.signIn(this.state.email, this.state.password)
        .then(() => {
          this.state.store.dispatch(ActionType.GetUserData);
          this.$router.push("Profile");
        })
        .catch((err) => {
          this.state.error = true;
          console.error(err);
        });
    },
    async signIn(username: string, password: string) {
      try {
        const user = await Auth.signIn(username, password);
        return Promise.resolve("Successfully logged in!");
      } catch (error) {
        return Promise.reject("Error when logging in");
      }
    },
  },
});
