
import { Options, Vue } from "vue-class-component";

import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";

import Navbar from "@/components/Navbar.vue";
import FooterPanel from "@/components/Footer.vue";
import SignUpForm from "@/components/SignUpForm.vue";

applyPolyfills().then(() => {
  defineCustomElements(window);
});

@Options({
  components: {
    Navbar,
    SignUpForm,
    FooterPanel,
  },
})
export default class App extends Vue {}
