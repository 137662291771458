
import { Options, Vue } from "vue-class-component";

import mixpanel from "mixpanel-browser";

import Navbar from "@/components/Navbar.vue";
import FooterPanel from "@/components/Footer.vue";

@Options({
  components: {
    Navbar,
    FooterPanel,
  },
})
export default class App extends Vue {
  mounted() {
    mixpanel.track("Unknown Creator Page Visit");
  }
}
