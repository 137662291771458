
import { Options, Vue } from "vue-class-component";

import SignInForm from "@/components/SignInForm.vue";

@Options({
  components: {
    SignInForm,
  },
})
export default class App extends Vue {}
