
import { Options, Vue } from "vue-class-component";
import { computed } from "vue";

import mixpanel from "mixpanel-browser";

import HeroHeader from "@/components/HeroHeader.vue";
import Navbar from "@/components/Navbar.vue";
import FeaturesPanel from "@/components/ExplainerPanel.vue";
import CreatorProfile from "@/components/profile/CreatorProfile.vue";
import FooterPanel from "@/components/Footer.vue";

import { useStore } from "@/store";
import { ActionType } from "@/store/actions";

@Options({
  components: {
    Navbar,
    HeroHeader,
    FeaturesPanel,
    CreatorProfile,
    FooterPanel,
  },
})
export default class App extends Vue {
  async mounted() {
    mixpanel.track("Profile Page Visit");

    const store = useStore();
    const userData = computed(() => store.getters.getUserData);
    if (userData.value === undefined) {
      await store.dispatch(ActionType.GetUserData);
    }
    if (!userData.value!.ProfileSetup) {
      console.log("AAA " + JSON.stringify(userData.value!));
      this.$router.push("ProfileSetup");
    }
  }
}
