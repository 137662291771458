
import { Options, Vue } from "vue-class-component";

import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";

import ProfileSetupForm from "@/components/ProfileSetupForm.vue";

applyPolyfills().then(() => {
  defineCustomElements(window);
});

@Options({
  components: {
    ProfileSetupForm,
  },
})
export default class App extends Vue {}
