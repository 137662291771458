
import { computed, defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";

import { useStore } from "@/store";
import { ActionType } from "@/store/actions";

import { UserData } from "@/model/user";
import { updateUserProfile, fetchPublicUserProfile } from "@/model/user";

export default defineComponent({
  props: {
    editable: Boolean,
  },
  async setup(props) {
    const state = reactive({
      userData: {} as UserData,
      localUserData: {} as UserData,
      firstNameBoxStyles: "border border-green-900 bg-green-100",
      lastNameBoxStyles: "border border-green-900 bg-green-100",
      headingBoxStyles: "border border-green-900 bg-green-100",
      descriptionBoxStyles: "border border-green-900 bg-green-100",
      editing: false,
      editable: props.editable,
    });

    if (props.editable) {
      const store = useStore();
      const userData = computed(() => store.getters.getUserData);
      if (userData.value === undefined) {
        await store.dispatch(ActionType.GetUserData);
      }
      state.userData = userData.value!;
    } else {
      const route = useRoute();
      let resp = await fetchPublicUserProfile(
        route.params["username"] as string
      );
      state.userData = resp.data as UserData;
    }

    try {
      state.localUserData = JSON.parse(JSON.stringify(state.userData));
    } catch (e) {
      console.log("Failed to initialize user obj copy!");
    }

    return {
      state,
    };
  },

  methods: {
    editProfile() {
      console.debug("Editing user bio");
      this.state.editing = true;
    },
    saveProfileChanges() {
      console.debug("Saving user bio changes");

      if (this.profileChanged()) {
        updateUserProfile(this.state.localUserData)
          .then(() => {
            try {
              this.state.userData = JSON.parse(
                JSON.stringify(this.state.localUserData)
              );
            } catch (e) {
              console.error("Failed to update user obj!");
            }
            const store = useStore();
            store.dispatch(ActionType.GetUserData);
            this.state.editing = false;
            console.log("Successfully updated user profile section!");
          })
          .catch((e) => {
            console.error("Failed to update profile section!");
          });
      } else {
        console.debug("No new user bio changes to save");
        this.state.editing = false;
      }
    },
    discardProfileChanges() {
      console.debug("Discarding bio changes");
      this.setLocalToServerData();
      this.state.editing = false;
    },
    onTextBoxChange(userData: string, localData: string): string {
      if (userData !== localData) {
        return "border border-blue-900 bg-blue-100";
      }
      return "border border-green-900 bg-green-100";
    },
    profileChanged() {
      if (this.state.localUserData.Email !== this.state.userData.Email)
        return true;
      if (
        this.state.localUserData.Bio.FirstName !==
        this.state.userData.Bio.FirstName
      )
        return true;
      if (
        this.state.localUserData.Bio.LastName !==
        this.state.userData.Bio.LastName
      )
        return true;
      if (
        this.state.localUserData.Bio.ProfileHeading !==
        this.state.userData.Bio.ProfileHeading
      )
        return true;
      if (
        this.state.localUserData.Bio.ProfileDescription !==
        this.state.userData.Bio.ProfileDescription
      )
        return true;

      return false;
    },
    setLocalToServerData() {
      try {
        this.state.localUserData = JSON.parse(
          JSON.stringify(this.state.userData)
        );
      } catch (e) {
        console.error("Failed to initialize user obj copy!");
      }
    },
  },
});
