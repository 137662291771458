
import { defineComponent, reactive, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  minLength,
  sameAs,
  helpers,
} from "@vuelidate/validators";

import { Auth } from "aws-amplify";

import mixpanel from "mixpanel-browser";

import { createUserProfile } from "@/model/user";

export default defineComponent({
  setup() {
    const state = reactive({
      email: "",
      password: {
        password: "",
        confirm: "",
      },
      createError: "",
      verificationCode: "",
      emailErrors: false,
      currentStep: 0,
    });

    //eslint-disable-next-line
    const formatSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    const specialCharsRule = (value: string) => formatSpecial.test(value);

    const formatUpper = /[A-Z]+/;
    const upperCharsRule = (value: string) => formatUpper.test(value);

    const rules = computed(() => {
      return {
        email: {
          required: helpers.withMessage(
            "Please provide an email address",
            required
          ),
          email: helpers.withMessage(
            "Please provide a valid email address",
            email
          ),
        },
        password: {
          password: {
            required: helpers.withMessage("Please provide password", required),
            minLength: helpers.withMessage(
              "Please provide a longer password (minumim length of 6 characters)",
              minLength(6)
            ),
            upperChars: helpers.withMessage(
              "Please include a capital letter in your password",
              upperCharsRule
            ),
            specialChars: helpers.withMessage(
              "Please include a special symbol in your password",
              specialCharsRule
            ),
          },
          confirm: {
            required: helpers.withMessage(
              "Please confirm your password",
              required
            ),
            sameAs: helpers.withMessage(
              "Passwords do not match",
              sameAs(state.password.password)
            ),
          },
        },
      };
    });

    const v$ = useVuelidate(rules, state);

    return {
      state,
      v$,
    };
  },

  mounted() {
    mixpanel.track("SignupPageLoad");
  },

  methods: {
    submitProfileForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.signUp(this.state.email, this.state.password.password)
          .then(() => {
            this.state.currentStep++;
          })
          .catch((error) => {
            this.state.createError = error;
          });
      } else {
        // alert("Form failed validation");
      }
    },
    submitVerificationForm() {
      this.verify(
        this.state.email,
        this.state.password.password,
        this.state.verificationCode
      )
        .then(() => {
          this.$router.push("ProfileSetup");
        })
        .catch((err) => {
          console.error("Error verifying user:", err);
        });
    },
    resentVerificationCode() {
      Auth.resendSignUp(this.state.email);
      this.state.emailErrors = false;
      this.state.verificationCode = "";
    },
    clearErrors() {
      this.v$.$reset();
    },
    async signUp(username: string, password: string) {
      try {
        const { user } = await Auth.signUp({
          username,
          password,
        });
        mixpanel.track("ProfileCreated");
      } catch (error) {
        console.log("Error signing up:", error);
        return Promise.reject(error);
      }
    },
    async verify(username: string, password: string, verificationCode: string) {
      try {
        await Auth.confirmSignUp(username, verificationCode);
        mixpanel.track("ProfileVerified");
      } catch (error) {
        this.state.emailErrors = true;
        return Promise.reject("Failed to confirm sign up!");
      }
      return Auth.signIn({ username, password }).then(() => {
        createUserProfile();
      });
    },
  },
});
