
import { defineComponent, reactive, computed } from "vue";

import { updateUserProfile } from "@/model/user";

import { useStore } from "@/store";
import { ActionType } from "@/store/actions";

export default defineComponent({
  async setup() {
    const store = useStore();
    const userData = computed(() => store.getters.getUserData);
    if (userData.value === undefined) {
      await store.dispatch(ActionType.GetUserData);
    }

    const state = reactive({
      formPosition: 0,
      animation: "animation-in",
      formGroup: [
        {
          title: "Help us set up your Profile",
          subtitle: "What is your name?",
          fields: [
            { label: "First Name", value: "" },
            { label: "Last Name", value: "" },
          ],
        },
        {
          title: "Almost there",
          subtitle: "What's the username your online community knows you by?",
          fields: [{ label: "MrBeast, PewDiePie..", value: "" }],
        },
      ],
      userData: userData.value!,
      store,
    });

    return {
      state,
    };
  },

  methods: {
    submitForm() {
      console.log("Next");
      this.nextStep();
    },
    nextStep() {
      this.state.formPosition += 1;
      if (this.state.formPosition === this.state.formGroup.length) {
        const newUserData = {
          Id: this.state.userData.Id,
          CreatedAt: this.state.userData.CreatedAt,
          UpdatedAt: this.state.userData.UpdatedAt,
          ProfileSetup: true,
          Email: this.state.userData.Email,
          Bio: {
            FirstName: this.state.formGroup[0].fields[0].value,
            LastName: this.state.formGroup[0].fields[1].value,
            ProfileHeading: "Online Content Creator",
            ProfileDescription: "An awesome content creator",
          },
          Username: this.state.formGroup[1].fields[0].value,
        };
        console.debug("Writing new user data " + JSON.stringify(newUserData));
        updateUserProfile(newUserData).then(() => {
          this.state.store.dispatch(ActionType.InvalidateLocalUserData);
          this.$router.push("Profile");
        }).catch(e => {
          console.error("Failed to set user profile! Error: " + JSON.stringify(e));
        });
      }
    },
    mutateFieldData(formIdx: number, fieldIdx: number, e: any) {
      console.debug("Mutating " + formIdx + "-" + fieldIdx);
      this.state.formGroup[formIdx].fields[fieldIdx].value = e.target.value;
    },
  },
});
