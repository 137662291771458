
import { computed, defineComponent, reactive } from "vue";

import { useStore } from "@/store";
import { ActionType } from "@/store/actions";

import { UserData } from "@/model/user";
import { updateUserProfile } from "@/model/user";

export default defineComponent({
  async setup() {
    const store = useStore();
    const userData = computed(() => store.getters.getUserData);
    if (userData.value === undefined) {
      await store.dispatch(ActionType.GetUserData);
    }

    const state = reactive({
      userData: userData.value!,
      addSectionButtonToggled: false,
      sectionButtonStyles: "",
    });

    return {
      state,
    };
  },

  methods: {
    newSectionButtonToggle() {
      console.debug("Add new user profile section");
      this.state.addSectionButtonToggled = !this.state.addSectionButtonToggled;
      if (this.state.addSectionButtonToggled) {
        this.state.sectionButtonStyles = "ring-4 ring-blue-300";
      } else {
        this.state.sectionButtonStyles = "";
      }
    },
    cancelNewSectionToggle() {
      if (!this.state.addSectionButtonToggled) return;
      this.state.addSectionButtonToggled = false;
      this.state.sectionButtonStyles = "";
    },
    addSection(sectionId: string) {
      if (sectionId === "QuickLinks") {
        console.debug("Added QuickLinks");
        this.state.userData.QuickLinks = [];
        updateUserProfile(this.state.userData);
        this.cancelNewSectionToggle();
      }
    },
  },
});
